.header-top{
      .ant-badge{
            margin-left: 1rem;
            box-shadow: none;
            button{
                  background: inherit;
                  border: none !important;
                  box-shadow: none !important;
                  outline: none !important;
                  cursor: inherit;

                  i{
                        cursor: pointer;
                  }

                  &::after{
                        content: none;
                  }
            }
      }
}
.dropdown-notification{
      font-family: var(--main-font-family);
      width: 480px;
      max-width: 480px;
      position: absolute;
      background: #F4F4F4;
      //height: 450px;
      .wp-notifications{
            padding: 2rem 1rem;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            .head{
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 2rem;
                  h3.title{
                        font-weight: 500;
                        font-size: 22px;
                        color: var(--primary-color);
                        margin-bottom: 0;
                  }
                  .anticon{
                        color: #000;
                        font-size: 16px;
                        font-weight: 500;
                  }
            }
            .list-notification{
                  max-height: 90vh;
                  overflow: auto;

                  .item{
                        display: flex;
                        background: #fff;
                        margin-bottom: 1rem;
                        padding: 1rem;
                        input{
                              margin-top: 5px;
                              height: fit-content;
                              display: none;
                              margin-right: 1rem;
                        }
                        span{
                              i{
                                    font-size: 5px;
                                    margin-right: 1rem;
                                    visibility: hidden;
                                    opacity: 0;
                              }
                              &.un-read{
                                    i{
                                          visibility: visible;
                                          opacity: 1;
                                          color: var(--primary-color);
                                    }
                              }

                        }
                        .detail{
                              .title{
                                    font-size: 14px;
                                    color: #000;
                                    font-weight: 500;
                                    margin-bottom: 1rem;
                                    cursor: pointer;
                              }
                              .date{
                                    color: #3F3F3F;
                                    font-size: 12px;

                              }
                        }
                        .action-btn{
                              margin-left: auto;
                              .anticon{
                                    color: #000;
                              }
                        }

                        &.item-checkbox{
                              input{
                                    display: block;
                              }
                              span.icon{
                                    display: none;
                              }
                        }
                  }
                  p.no-item{
                        font-weight: 500;
                  }
            }

      }
}

.menu-notification{
      font-family: var(--second-font-family);
      li{
            padding: 0 12px;
            button{
                  border: none;
                  background: inherit !important;
                  font-weight: 500;
            }
            &:hover{
                  button{
                        color: var(--primary-color);
                  }
            }

      }
}
.menu-item-notification{
      li{
            &:last-child{
                  button{
                        color: red;
                  }

            }


      }
}