.modal-update-info-customer{
      form{
            label{
                  text-transform: uppercase;
            }

            textarea{
                  color: #000 !important;
                  font-weight: 400;
                  font-size: 14px;
            }

            .ant-radio-group{
                  label{
                        margin-right: 1rem;
                        height: 44px;
                        line-height: 44px;
                        padding: 0 3rem;
                        box-shadow: none !important;
                        outline: none;
                        border-radius: 20px;
                        border: 1px solid #d9d9d9;

                        &::before{
                              content: none;
                        }

                        &.ant-radio-button-wrapper-checked{
                              background: var(--primary-color) !important;
                              color: #fff;
                              border-color: var(--primary-color) !important;

                              &::before{
                                    content: none;
                              }
                        }

                        span{
                              font-weight: 600;
                              font-size: 13px;
                        }
                  }
            }
      }
}