.modal-service{
      .form-wp{
            width: 60%;
            margin: 0 auto;

            .upload-avatar-service{
                  text-align: center;

                  .upload-btn{
                        i{
                              color: #808080;
                        }

                        .text-upload-btn{
                              color: #808080;
                              margin-top: 5px;
                              font-size: 13px;
                        }
                  }

            }
      }
}