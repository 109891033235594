.ant-modal-centered::before{
      height: 0;
}
.modal-general{
      padding-top: 30px;
      width: 720px !important;
      .head-modal {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 3rem;
            p.title{
                  font-weight: 600;
                  font-size: 20px;
                  text-transform: uppercase;
                  margin-bottom: 0;
                  color: var(--primary-color);

                  span.date{
                        font-size: 16px;
                        text-transform: lowercase;
                  }
            }
            button {
                  border: none;
                  font-weight: 500;
                  font-size: 13px;
                  box-shadow: none;
                  outline: none;
            }
      }

      .body-modal{
            form{
                  label{
                        color: #000;
                        font-weight: 500;
                        font-size: 14px;
                  }
                  .ant-input{
                        padding: 5px 10px;
                        border-radius: 5px;
                        height: 56px;
                        font-size: 18px;
                        color: var(--button-background-color);

                        &::placeholder{
                              font-size: 14px;
                        }
                  }

                  .ant-select-selector{
                        height: 56px;
                        font-size: 18px;
                        color: var(--button-background-color);
                        box-shadow: none !important;
                        outline: none !important;

                        .ant-select-selection-item{
                              line-height: 56px;
                        }

                        .ant-select-item{
                              height: 56px;
                        }

                        .ant-select-selection-placeholder{
                              line-height: 48px;
                              font-size: 14px;
                        }
                  }



                  .ant-select:focus, .ant-select:hover, .ant-select-focused {
                        border-color: var(--button-background-color) !important;
                        border-right-width: 1px;
                        box-shadow: none !important;
                        outline: none !important;

                        .ant-select-selector{
                              border-color: var(--button-background-color) !important;
                        }
                  }
                  .ant-select-selector:focus, .ant-select-selector:hover, .ant-select-selector-focused {
                        border-color: var(--button-background-color);
                        border-right-width: 1px;
                        box-shadow: none;
                        outline: 0;
                  }

                  .ant-input:focus, .ant-input:hover, .ant-input-focused {
                        border-color: var(--button-background-color);
                        border-right-width: 1px;
                        box-shadow: none;
                        outline: 0;
                  }

                  .ant-input-password:focus, .ant-input-password:hover, .ant-input-password-focused {
                        border-color: var(--button-background-color);
                        border-right-width: 1px;
                        box-shadow: none;
                        outline: 0;
                  }
                  button[type=submit]{
                        background: var(--button-background-color);
                        border: none;
                        font-weight: 500;
                        height: 50px;
                        width: 100%;
                        text-transform: uppercase;
                  }
            }
      }

      &.modal-report{
            .head-modal{
                  .left{
                        display: flex;
                        justify-content: left;
                        align-items: center;

                        .title{
                            margin-right: 10px;
                        }

                        .ant-picker{
                              width: 256px;
                        }

                        .ant-picker:focus, .ant-picker:hover, .ant-picker-focused {
                              border-color: var(--button-background-color);
                              border-right-width: 1px;
                              box-shadow: none;
                              outline: 0;
                        }
                  }
            }

            .body-modal{
                  .reports{
                        .head-col{
                              display: grid;
                              grid-template-columns: repeat(5, 1fr);
                              > p{
                                    font-weight: 500;
                                    font-size: 16px;
                                    text-transform: uppercase;
                                    color: #000;
                              }
                        }
                        .body-col{
                              max-height: 70vh;
                              overflow-y: auto;

                              &::-webkit-scrollbar {
                                    width: 5px;
                              }

                              &::-webkit-scrollbar-track {
                                    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                              }

                              &::-webkit-scrollbar-thumb {
                                    background-color: darkgrey;
                                    outline: 1px solid slategrey;
                              }
                              > .item {
                                    display: grid;
                                    grid-template-columns: repeat(5, 1fr);
                                    border-bottom: 1px solid #eee;
                                    padding-top: 10px;
                                    &:last-child{
                                          border: none;
                                    }
                                    > div{
                                          padding-right: 10px;
                                    }

                                    p{
                                          color: #000;
                                          font-weight: 500;
                                    }
                              }
                        }
                  }
            }

            .footer-modal{
                  padding-top: 1rem;
                  border-top: 1px solid #eee;
                  .total-amount{
                        p{
                              font-weight: 600;
                              text-align: right;
                              font-size: 16px;
                              text-transform: uppercase;
                        }

                  }
            }
      }

      &.modal-change-password {
            .body-modal {
                  form {
                        .ant-input {
                              height: 36px
                        }
                  }
            }
      }

      &.modal-table{
            .body-modal{
                  .ant-tabs-tab{
                        text-transform: uppercase;
                        font-weight: 500;
                        .ant-tabs-tab-btn{
                              color: var(--main-text-color);
                        }
                  }
                  .ant-tabs-ink-bar{
                        background: var(--button-background-color);
                  }
                  .ant-tabs-nav{
                        margin-bottom: 3rem;
                  }
            }
      }

      &.modal-staff-panel{
            .footer-modal{
                  margin-top: 10px;
                  button{
                        width: 100%;
                        height: 48px;
                        border-radius: 5px;
                        border: none;
                        text-transform: uppercase;
                        color: #fff;
                        font-size: 14px;
                        font-weight: 500;
                        &.btn-check-in{
                              background: #0652DD;
                        }

                        &.btn-check-out{
                              background: #f1c40f;
                        }
                  }
            }
      }
}

.modal-table{
      width: 80% !important;

      .status{
            font-size: 13px;
            &.offline{
                  color: #f1c40f;
            }
            &.available{
                  color: #4cd137;
            }
            &.active{
                  color: #4cd137;
            }
            &.busy{
                  color: #e84118;
            }
            &.inactive{
                  color: #e84118;
            }
      }
      .table-general{
           table{
                 thead tr th:first-child, tbody tr td:first-child{
                       display: none;
                 }
                 thead tr th, tbody tr td{
                       font-weight: 500;

                 }
                 tbody tr td{
                       p{
                             margin-bottom: 0;
                       }
                       p.price{
                             position: relative;
                             padding-left: 10px;
                       }
                       .currency{
                             position: absolute;
                             top: 48%;
                             left: 0;
                             transform: translateY(-50%);
                             font-size: 13px;
                             margin-right: 1px;
                       }
                       .ant-avatar{
                             margin-right: 0.5rem;
                       }

                       .customer-type{
                             font-size: 12px;
                             font-weight: 500;
                              //&.none{
                              //      color: #fff;
                              //      background-color: #FFC312;
                              //      padding: 5px 10px;
                              //      border-radius: 10px;
                              //}
                       }
                 }
           }
            .action-row{
                  button{
                        margin-right: 5px;
                        color: #fff;
                        border: none;
                        border-radius: 5px;
                        margin-top: 5px;
                        font-size: 13px;
                        > i{
                              font-size: 11px;
                              margin-right: 5px;
                        }
                  }
                  .edit-row-btn, .check-in-btn{
                        background: #3742fa;
                  }

                  .delete-row-btn{
                        background: #e84118;
                  }

                  .check-out-btn{
                        background: #FFC312;
                  }
                  .btn-detail{
                        border: 1px solid var(--primary-color);
                        color: var(--primary-color);
                        font-size: 12px;
                  }
                  .history-btn{
                        background: #4cd137;
                  }
            }

      }
}

.date-rq-dropdown{
      font-family: var(--main-font-family);
      .ant-picker-panel-container{
            .ant-picker-datetime-panel{
                  .ant-picker-header{
                        .ant-picker-header-view{
                              font-weight: 600;
                              button{
                                    font-weight: 600;
                              }
                        }

                  }

                  .ant-picker-date-panel{
                        .ant-picker-content{
                              tbody {
                                    td{
                                          font-weight: 400;
                                          &.ant-picker-cell-disabled{
                                                div{
                                                      text-decoration: line-through;
                                                }
                                          }
                                    }
                              }
                        }
                  }
                  .ant-picker-time-panel{
                        .ant-picker-content{
                              ul {
                                    li{
                                          font-weight: 400;
                                    }
                              }
                        }
                  }
            }
      }
      .ant-picker-footer{
            .ant-picker-ok{
                  width: 100%;
                  button{
                        width: 100%;
                        font-size: 12px;
                        font-weight: 600;
                        height: 40px;
                        border: none;
                        background: var(--primary-color);
                  }
            }
      }
}

.modal-choose-staff{
      .ant-form-item-label{
            label{
                  text-transform: uppercase;
                  color: var(--primary-color) !important;
                  font-size: 16px;
                  font-weight: 600 !important;
            }
      }
      .ant-form-item{
            input{
                  height: 44px !important;
                  &::placeholder{
                        font-size: 14px !important;
                  }
            }
      }
      .list-staffs{
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 10px 10px;
            .ant-radio-button-wrapper{
                  padding: 5px 15px;
                  height: auto;
                  color: var(--primary-color);
                  box-shadow: none;
                  border-radius: 5px;
                  border: 1px solid #dcdcdc;
                  font-weight: 400;
                  font-size: 14px;
                  &::before{
                        content: none;
                  }
                  &.ant-radio-button-wrapper-checked{
                        color: #fff;
                        background: var(--primary-color);
                        border-color: var(--primary-color);
                  }
            }
      }

}

.modal-notification-appointment{
      font-family: var(--second-font-family);
      .ant-modal-body{
            background: var(--primary-color);
            //padding: 0;
            padding: 1rem 3rem;
      }
      .body-modal{
            border-radius: 20px;
            text-align: center;
            padding: 3rem 2rem;
            background: #fff;

            .content{
                  .block{
                        .head{
                              border-bottom: 1px solid rgba(128, 128, 128, 0.27);
                              margin-bottom: 2rem;
                              text-align: center;
                              .title{
                                    color: var(--primary-color);
                                    font-size: 20px;
                                    font-weight: 500;
                              }
                              .sub-title{
                                    font-size: 16px;
                                    color: #000;
                              }
                        }
                        .detail{
                              > div{
                                    > p{
                                          font-weight: 500;
                                          font-size: 16px;
                                          display: flex;
                                          justify-content: space-between;
                                          align-items: center;

                                          span{
                                                &:first-child{
                                                      min-width: 100px;
                                                      text-align: left;
                                                }
                                                li.ant-rate-star{
                                                      width: 20px;
                                                      font-size: 14px;
                                                }
                                          }
                                    }
                                    .content-feedback{
                                          p{
                                                font-weight: 500;
                                                text-align: left;
                                          }

                                    }
                                    &.service{
                                          border-bottom: 1px solid rgba(128, 128, 128, 0.27);
                                          margin-bottom: 2rem;
                                          p{
                                                span{
                                                      font-size: 14px;
                                                      &:first-child{
                                                            color: #c1c1c1;

                                                      }
                                                }
                                          }
                                    }
                              }
                        }
                  }
            }

            button{
                  background: var(--primary-color);
                  color: #fff;
                  font-weight: 500;
                  height: 48px;
                  width: 100%;
                  margin-top: 1rem;
                  border: none;
                  box-shadow: none;
                  outline: none;
                  &.close{
                        background: none;
                        color: #000;
                  }
            }
      }
}

.modal-appointment-of-customer{
      .appointments-customer{
            padding: 3rem;
            max-height: 80vh;
            overflow: auto;
            background: #F4F4F4;
      }
      .item{
            background: #fff;
            margin-bottom: 1rem;
            padding: 1rem;

            p{
                  display: flex;
                  justify-content: space-between;
                  span{
                        color: #000;
                        font-size: 14px;


                        &:first-child{
                              min-width: 150px;
                              color: #808080;
                        }
                        &:last-child{
                              width: 60%;
                              font-weight: 500;
                              text-align: right;
                        }
                        &.appointment-id{
                              color: #000;
                              font-weight: 600;
                        }
                  }
            }
      }
}

.modal-history-check-in-out{
      width: 90% !important;
      .date-filter{
            padding: 30px 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            h6{
                  color: #000;
                  text-align: center;
                  margin: 0;
                  font-size: 18px;
                  font-weight: 600;
                  span{
                        padding: 0 1rem ;
                  }
            }
            > button{
                  background: none;
                  border: none;
                  margin: 0;
                  padding: 0;
                  line-height: 0;
                  color: #000;
                  font-weight: 500;
                  > .anticon{
                        color: #000;
                        cursor: pointer;
                        font-size: 13px;
                        font-weight: 600;
                  }
                  &:disabled{
                        .anticon{
                              color: #808080;
                        }
                  }


            }
      }

      .histories{
            //display: grid;
            //grid-template-columns: repeat(7, 1fr);
            display: flex;
            justify-content: space-between;
            .item{
                  .day{
                        font-weight: 600;
                        text-align: center;
                  }
                  .list-check-in-out{
                        display: flex;
                        justify-content: space-between;
                        background: #e6e6e6;
                        > .in {
                              margin-right: 5px;
                              border-right: 1px solid rgba(128, 128, 128, 0.45);
                              padding-right: 5px;
                        }

                        > div {
                              p{
                                    font-size: 14px;
                                    text-align: center;
                                    margin-bottom: 0;
                                    font-weight: 600;
                                    padding: 5px;
                                    &.title{
                                          font-weight: 400;
                                    }
                              }
                        }
                  }

                  &:nth-child(2n){
                        .list-check-in-out{
                              background: #f8f8f8;
                        }
                  }
            }
      }
}

.input-search{
      margin-bottom: 3rem;
      position: relative;
      width: 100%;
      min-width: 0;
      line-height: 1.5715;
      border: 1px solid #808080;
      border-radius: 1rem;
      transition: all 0.3s;
      display: inline-flex;
      background: inherit;
      color: #fff;
      outline: none !important;
      box-shadow: none !important;

      > .ant-input{
            border: none;
      }
      .anticon{
            color: #9b9b9b;
            margin-left: 10px;
            font-size: 13px;
      }
      input{
            height: 40px;
            text-align: center;
            padding: 0 30px;
            font-size: 20px;
            background: inherit;
            &::placeholder{
                  font-size: 14px;
            }
      }
      &.ant-input-affix-wrapper-focused, &:hover {
            border: 1px solid var(--primary-color) !important;
            box-shadow: none;
            outline: 0;
      }
}

.modal-staff{
      width: 85% !important;

      .form-staff{
            display: flex;
            justify-content: space-between;
            align-items: center;
            > .left{
                  width: 150px;
            }
            .right{
                  width: calc(100% - 150px);
                  padding: 0 2rem;
            }
            .ant-upload{
                  width: 100%;
                  height: 150px;
            }

            input{
                  border: none;
                  border-bottom: 1px solid #808080;
                  border-radius: 0 !important;
                  &::placeholder{
                        font-size: 14px !important;
                        font-weight: 300;
                        color: #808080;
                  }
            }
      }
}

.modal-confirm-logout{
      .ant-modal-confirm-body{
            > .anticon{
                  color: var(--primary-color);
            }
      }
      .ant-modal-confirm-btns{
            text-align: center;
            button{
                  background: var(--primary-color);
                  border: none;
                  color: #fff;
                  font-weight: 500;
                  height: 40px;

                  &:first-child{
                        background: rgba(128, 128, 128, 0.64);
                  }

            }
      }
}

.modal-report{
      width: 90% !important;
}

.modal-loading{
      //top: 30% !important;
      //transform: translateY(-50%);

      .ant-modal-content{
            background: none;
            box-shadow: none;

            .wp-loading-data{
                  .anticon, .ant-spin-text{
                        color: #fff;
                  }
            }
      }
}

@media screen and (max-width: 760px) {
      .modal-general{
            &.modal-report{
                  .body-modal{
                        .reports{
                              .head-col{
                                    text-align: center;
                                    > p{
                                          font-size: 13px;
                                    }
                              }
                        }
                  }
            }
      }
}

@media screen and (min-width: 1600px){
      .modal-general{
            width: 1400px !important;
            .head-modal {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 3rem;
                  p.title{
                        font-size: 30px;
                        span.date{
                              font-size: 22px;
                              text-transform: lowercase;
                        }
                  }
                  button {
                        font-size: 18px;
                  }
            }

            .body-modal{
                  form{
                        label{
                              color: #000;
                              font-weight: 500;
                              font-size: 20px;
                        }
                        .ant-input{
                              height: 70px;
                              font-size: 26px;
                        }

                        .ant-select-selector{
                              height: 70px;
                              font-size: 18px;
                              .ant-select-selection-item{
                                    line-height: 70px;
                              }
                              .ant-select-item{
                                    height: 70px;
                              }
                              .ant-select-selection-placeholder{
                                    line-height: 70px;
                                    font-size: 18px;
                              }
                        }

                        button[type=submit]{
                              height: 72px;
                              font-size: 22px;
                        }
                  }
            }

            &.modal-create-appointment{
                  .customer-info , .request-info{
                        p.title{
                              font-size: 20px;
                        }

                  }

                  .ant-form-item{
                        &.choose-staff{
                              margin-bottom: 5px;
                        }
                        .ant-picker{
                              height: 70px;
                              font-size: 20px;
                              color: var(--button-background-color);
                              .ant-picker-input{
                                    input{
                                          font-size: 20px;
                                    }
                              }
                        }
                        .list-services{
                              .ant-checkbox-wrapper{
                                    .ant-checkbox{
                                          display: none;
                                    }
                                    > span{
                                          padding: 20px 10px;
                                    }
                              }
                        }
                  }
            }

            &.modal-report{
                  .head-modal{
                        .left{
                              .title{
                                    margin-right: 30px;
                              }

                              .ant-picker{
                                    width: 400px;
                                    .ant-picker-input{
                                          input{
                                                font-size: 20px;
                                          }
                                    }
                              }
                        }
                  }

                  .body-modal{
                        .reports{
                              .head-col{
                                    > p{
                                          font-size: 22px;
                                    }
                              }
                              .body-col{
                                    > .item {
                                          > div{
                                                padding-right: 10px;
                                                p{
                                                      font-size: 20px;
                                                }
                                          }
                                    }
                              }
                        }
                  }

                  .footer-modal{
                        .total-amount{
                              p{
                                    font-size: 24px;
                              }

                        }
                  }
            }

            &.modal-change-password {
                  .body-modal {
                        form {
                              .ant-input {
                                    height: 70px;
                                    font-size: 26px;
                              }
                        }
                  }
            }

            &.modal-table{
                  .body-modal{
                        .ant-tabs-tab{
                              text-transform: uppercase;
                              font-weight: 500;
                              .ant-tabs-tab-btn{
                                    color: var(--main-text-color);
                                    font-size: 20px;
                              }
                        }
                        .ant-tabs-ink-bar{
                              background: var(--button-background-color);
                        }
                        .ant-tabs-nav{
                              margin-bottom: 3rem;
                        }
                  }
            }

            &.modal-appointment-detail{
                  .body-modal{
                        .appointment-detail{
                              .detail{
                                    .item{
                                          > div{
                                                font-size: 22px;
                                                margin-bottom: 15px;
                                                &.value{
                                                      .staff{
                                                            button{
                                                                  border: none;
                                                                  background: #ff0000;
                                                                  margin-left: 10px;
                                                                  font-weight: 500;
                                                                  color:#fff;
                                                                  border-radius: 5px;
                                                                  height: 70px;
                                                                  font-size: 22px;
                                                            }
                                                      }
                                                      .choose-staff{
                                                            .ant-select-selector{
                                                                  height: 60px;
                                                                  font-size: 20px;
                                                            }
                                                            .ant-select-selection-item{
                                                                  line-height: 60px;
                                                            }
                                                            .ant-input{
                                                                  height: 20px;
                                                            }
                                                            .btn-change-staff{
                                                                  button{
                                                                        height: 60px;
                                                                        font-size: 20px;
                                                                  }
                                                            }
                                                      }
                                                }
                                          }
                                    }
                                    .action{
                                          button{
                                                height: 70px;
                                                font-size: 20px;
                                                i{
                                                      font-size: 16px;
                                                }
                                          }
                                    }
                              }
                              .action-appointment{
                                    padding-top: 10px;
                                    border-top: 1px solid #eee;
                                    text-align: center;
                                    .status-title{
                                          text-transform: uppercase;
                                          font-weight: 500;
                                          font-size: 20px;
                                    }
                                    .btn-status{
                                          button{
                                                font-size: 18px;
                                                height: 70px;
                                          }
                                    }
                                    .note{
                                          margin-top: 10px;
                                          p{
                                                font-size: 20px;
                                          }
                                    }
                                    .total-price{
                                          .label{
                                                font-size: 20px;
                                          }
                                          .ant-input{
                                                height: 70px;
                                                font-size: 22px;
                                          }
                                          button{
                                               height: 70px;
                                                font-size: 18px;
                                          }
                                    }
                                    .app-complete{
                                          font-size: 22px;
                                    }

                              }
                        }
                  }
                  .footer-modal{
                        button{
                              font-size: 20px;
                              height: 72px;
                              > i{
                                    font-size: 14px;
                              }
                        }
                  }
            }
      }

      .modal-table{
            width: 1400px !important;

            .table-general{
                  table{
                        thead tr th, tbody tr td{
                              font-size: 22px;
                        }
                        tbody tr td{
                              p.price{
                                    position: relative;
                                    padding-left: 10px;
                              }
                              .currency{
                                    position: absolute;
                                    top: 48%;
                                    left: 0;
                                    transform: translateY(-50%);
                                    font-size: 20px;
                                    margin-right: 1px;
                              }
                        }
                  }
                  .action-row{
                        button{
                              height: 60px;
                              > i{
                                    font-size: 13px;
                                    margin-right: 10px;
                              }
                              span{
                                    font-size: 20px;
                              }
                              span.anticon{
                                    font-size: 18px;
                              }

                              &.btn-detail{
                                    height: 34px;
                                    span{
                                          font-size: 16px;
                                    }
                              }

                        }
                  }

            }
      }
}

@media screen and (min-width: 1920px){

}