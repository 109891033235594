.sign-up-page{
      background: #f7f7f7;
      padding-bottom: 5rem;;
      /* Change the white to any color */
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 30px #fff inset !important;
            -webkit-text-fill-color: #000 !important;
      }

      .head{
            padding: 3rem 0 1rem 0;
            text-align: center;
            font-size: 30px;
            display: flex;
            width: 60%;
            margin: 0 auto;
            align-items: center;
            h3{
                  color: var(--three-color);
                  font-weight: 600;
                  margin-bottom: 0;
                  width: 100%;
            }
            button{
                  background: inherit;
                  border: none;
                  box-shadow: none;
                  font-weight: 500;
                  color: #000;

                  i{
                        margin-right: 0.5rem;
                  }
            }
      }

      .wp-form{
            width: 60%;
            margin: 0 auto;
            background: #ffffff;
            border-radius: 10px;
            padding: 2rem;
      }

      h4{
            color: var(--three-color);
            font-size: 18px;
            text-align: center;
      }

      .widget-head{
            text-align: center;
            margin: 3rem 0 2rem 0;
            h4{
                  color: var(--three-color);
                  font-size: 16px;
            }
      }

      .widget{
            &:first-child{
                  .widget-head{
                        margin: 0 0 2rem 0;
                  }
            }
      }

      .ant-input-affix-wrapper{
            border: none;
            border-bottom: 1px solid #f3f3f3;
            box-shadow: none;
            &:hover{
                  border-color: var(--three-color) !important;
            }
      }

      .anticon{
            font-size: 11px;
      }

      input, textarea{
            font-size: 16px;
            font-weight: 500;
            padding: 0.5rem !important;

            &::placeholder{
                  font-weight: 400;
                  font-size: 14px;
            }
      }

      textarea{
            box-shadow: none !important;
            border: 1px solid #f3f3f3 !important;

            &:focus{
                  border: 1px solid var(--three-color) !important;
            }
      }

      .ant-input-password-icon{
            font-size: 11px;
            font-weight: 500;
            cursor: pointer;
      }

      label{
            font-weight: 500;
      }
      .business-hour{
            background: #fff;
            padding: 1rem;

            .note{
                  //font-family: var(--second-font-family);
                  margin-bottom: 2rem;
                  p.label{
                        font-weight: 500;
                        margin-bottom: 0.5rem;
                  }
                  ul{
                        padding-left: 2rem;
                        list-style-type: disc;
                        li{
                              margin-bottom: 0.5rem;
                              font-weight: 500;
                              color: #808080;
                        }
                  }
            }
            .item{
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 1rem;
                  align-items: center;

                  .label-day{
                        width: 100px;
                        font-size: 13px;
                  }

                  .input-time{
                        width: calc(100% - 100px);
                        display: flex;
                        align-items: center;

                        > span{
                              padding:  0 2rem;
                              font-weight: 500;
                              color: #808080;
                              font-family: var(--second-font-family);
                        }

                        .ant-form-item{
                              width: 100%;
                              margin: 0;

                              .ant-picker{
                                    width: 100%;
                                    height: 40px;
                                    //border: 1px solid #f3f3f3;
                                    box-shadow: none;
                                    border: none;


                                    input{
                                          font-weight: 500;
                                          font-size: 16px;
                                          border: none;
                                          border-bottom: 1px solid #f3f3f3;
                                          &::placeholder{
                                                font-size: 13px;
                                                font-weight: 300;
                                          }
                                    }
                              }
                        }
                  }
            }
      }

      .mb-0{
            margin-bottom: 0 !important;
      }
      .mt-0{
            margin-top: 0 !important;
      }
      .mb-2{
            margin-bottom: 2rem !important;
      }
      p.desc{
            margin-bottom: 0;
            margin-top: 0.5rem;
            font-weight: 500;
            color: #b7b7b7;
      }

      .more-desc{
            margin-top: 2rem;
            border: 1px solid #ececec;
            padding: 1rem;

            p{
                  color: var(--main-text-color);
                  font-weight: 500;
                  &.email{
                        text-decoration: underline;
                  }
            }
      }

      .ant-upload-select{
            border: 1px solid var(--main-text-color) !important;
            .ant-upload{
                  background: var(--background-input-upload);
            }
            .anticon{
                  color: var(--main-text-color);
                  font-size: 26px;
            }
      }

      .widget-body{
            .wd-title, .wd-content .item{
                  margin-bottom: 1rem;
                  display: grid;
                  grid-template-columns: repeat(6, 1fr);
                  grid-template-areas: 'col1 col1 col1 col2 col2 col3';
                  column-gap: 5px;

                  > div{
                        text-align: left;
                        font-weight: 500;
                        &:first-child{
                              grid-area: col1;
                        }
                        &:nth-child(2){
                              grid-area: col2;
                        }
                        &:last-child{
                              text-align: center;
                        }
                        button{
                              border: none;
                              box-shadow: none;
                              font-weight: 500;
                              color: var(--three-color);

                              &:hover{
                                    color: var(--three-color);
                              }


                              i{
                                    margin-right: 0.5rem;
                              }
                        }

                        .staff-name{
                              display: grid;
                              grid-template-columns: 1fr 1fr;
                              column-gap: 5px;
                        }

                        input{
                              border: none;
                        }
                  }
            }
            .wd-content{
                  > .item{
                        border: 1px solid #f3f3f3;

                        .ant-form-item{
                              margin-bottom: 0;

                              input{
                                    box-shadow: none;
                                    padding: 1rem 0.5rem !important;
                                    position: relative;

                                    &:after{
                                          content: '';
                                          position: absolute;
                                          width: 100%;
                                          height: 100%;
                                          background: red;
                                          right: 0;
                                          top: 50%;
                                          z-index: 1000000;

                                    }
                              }
                        }

                        > div{

                              &:last-child{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    button{
                                          color: #f5967a;
                                          font-size: 12px;
                                    }
                              }

                        }
                  }
            }
      }

      .btn-form{
            text-align: center;
            margin-top: 2rem;
            button[type='submit']{
                  height: 50px;
                  width: 50%;
                  background: var(--three-color);
                  color: #fff;
                  font-weight: 600;
                  border: none;
                  box-shadow: none;
            }
      }
}

.modal-confirm-sign-up-success{
      width: 520px !important;
      .ant-modal-confirm-body{
            text-align: center;
            .anticon{
                  float: none;
                  font-size: 40px;
                  color: var(--three-color) !important;
            }
            h4{
                  font-weight: 600;
                  font-size: 20px;
                  color: #000;
                  text-align: center;
            }
            p{
                  font-size: 14px;
                  color: #808080;
                  text-align: center;
            }
      }
      .ant-modal-confirm-btns{
            text-align: center !important;
            button{
                  width: 30%;
                  height: 50px;
                  background: var(--three-color);
                  color: #fff;
                  border: none;
                  box-shadow: none;
                  font-weight: 500;
                  border-radius: 25px;
            }
      }
}
