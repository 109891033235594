.modal-salon{
      width: 90% !important;
      font-family: var(--main-font-family);
      .body-modal{
            form{
                  .ant-input{
                        padding-left: 2rem;
                        font-size: 16px;
                        height: 48px;

                        &::placeholder{
                              font-size: 13px;
                              font-weight: 300;
                        }
                  }

                  .upload-image-salon{
                        .ant-upload-select{
                              background: var(--main-background-input-upload);
                              .anticon{
                                    color: var(--primary-color);
                                    font-size: 15px;
                              }
                        }

                        .ant-upload-list-item{
                              border: 1px solid #f3f3f3;
                        }
                  }

                  .business-hour{
                        background: #fff;
                        padding: 1rem;
                        .note{
                              //font-family: var(--second-font-family);
                              margin-bottom: 2rem;
                              p.label{
                                    font-weight: 500;
                                    margin-bottom: 0.5rem;
                              }
                              ul{
                                    padding-left: 2rem;
                                    list-style-type: disc;
                                    li{
                                          margin-bottom: 0.5rem;
                                          font-weight: 500;
                                          color: #808080;
                                    }
                              }
                        }
                        .item{
                              display: flex;
                              justify-content: space-between;
                              margin-bottom: 1rem;

                              .label-day{
                                    width: 200px;
                              }

                              .input-time{
                                    width: calc(100% - 200px);
                                    display: flex;
                                    align-items: center;

                                    > span{
                                          padding:  0 2rem;
                                          font-weight: 500;
                                          color: #808080;
                                          font-family: var(--second-font-family);
                                    }

                                    .ant-form-item{
                                          width: 100%;
                                          margin: 0;

                                          .ant-picker{
                                                width: 100%;
                                                height: 40px;
                                                border: 1px solid #f3f3f3;
                                                box-shadow: none;


                                                input{
                                                      font-weight: 500;
                                                      font-size: 16px;
                                                      &::placeholder{
                                                            font-size: 13px;
                                                            font-weight: 300;
                                                      }
                                                }
                                          }
                                    }
                              }
                        }
                  }
            }
      }
}

.dropdown-schedule{
      .ant-picker-footer{
            .ant-picker-ok{
                  width: 100%;
            }
            button{
                  width: 100%;
                  font-weight: 500;
                  font-family: var(--second-font-family);
                  background: var(--primary-color);
                  border: none;
                  color: #fff;
            }
      }

}