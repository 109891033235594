.auth-page {
      .login-page {
            .wp-inner{
                  padding-top: 5rem;
            }
            .wp-form{
                  background-color: var(--primary-color);
                  width: 60%;
                  margin: 0 auto;
                  padding: 50px 30px;
                  border-radius: 10px;

                  .thumb{
                        width: 200px;
                        height: auto;
                        margin: 0 auto 2rem auto;

                        img{
                              max-width: 100%;
                        }
                  }
                  h3.title-form{
                        text-align: center;
                        margin-bottom: 0.5rem;
                        font-size: 20px;
                        font-weight: 600;
                        color: #fff;
                        text-transform: uppercase;
                  }
                  .or-sign-up{
                        text-align: center;
                        a{
                              color: #fff;

                              &:hover{
                                    text-decoration: underline;
                              }
                        }
                  }

                  .form-admin-login{
                        margin-top: 2rem;
                        .ant-input-affix-wrapper{
                              box-shadow: none !important;
                              border-color: #808080;
                              border-radius: 5px;
                        }
                        .ant-input-affix-wrapper > input.ant-input:focus {
                              box-shadow: inset 0 0 0 50px #fff !important;
                        }

                        .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
                              border-color: var(--button-background-color);
                              box-shadow: none !important;
                        }

                        .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper:hover .ant-input-affix-wrapper-focused {
                              border-color: var(--button-background-color);
                              box-shadow: none !important;
                        }

                        .ant-form-item{
                              margin-bottom: 15px;
                              label{
                                    color: #fff;
                                    font-weight: 500;
                                    font-size: 18px;
                              }
                              .ant-input{
                                    padding: 5px 10px;
                                    height: 48px;
                                    font-size: 18px;
                                    color: #000;
                                    box-shadow: inset 0 0 0 50px #fff;
                                    font-weight: 500;

                                    &::placeholder{
                                          font-weight: 300;
                                          font-size: 14px;
                                    }

                              }
                              .ant-input-suffix{
                                    .ant-input-password-icon{
                                          font-size: 11px;
                                          font-weight: 600;
                                          cursor: pointer;
                                    }
                              }

                              .ant-input-prefix{
                                    .anticon{
                                          font-size: 13px;
                                    }
                              }


                              .ant-input:focus, .ant-input:hover, .ant-input-focused {
                                    border-color: var(--button-background-color);
                                    border-right-width: 1px;
                                    background: #fff;
                                    box-shadow: inset 0 0 0 50px #fff;
                                    outline: 0;
                              }

                        }

                        .btn-form{
                              text-align: center;
                              button[type=submit], button.submit{
                                    background: #fff;
                                    border: none;
                                    font-weight: 500;
                                    height: 50px;
                                    width: 100%;
                                    text-transform: uppercase;
                                    color: var(--main-text-color);
                              }

                              .or-forgot-password{
                                    color: #fff;
                                    margin-top: 1rem;
                                    a{
                                          &:hover{
                                                text-decoration: underline;
                                          }
                                    }
                              }
                              a{
                                    color: #fff;
                                    font-family: var(--second-font-family);
                                    font-size: 14px;
                                    display: inline-block;

                                    &.link-to-login{
                                          text-align: center;
                                          margin-top: 1rem;
                                    }

                                    &:hover{
                                          text-decoration: underline;
                                    }
                              }

                              .resend{
                                    text-align: right;
                                    margin-bottom: 0.5rem;
                                    button.resend-code{
                                          border: none;
                                          position: relative;
                                          padding: 0 15px;
                                          height: 0;
                                          line-height: 0;
                                          font-size: 13px;
                                          color: #fff;
                                          i{
                                                position: absolute;
                                                left: 0;
                                                top: 50%;
                                                transform: translateY(-50%);
                                                font-size: 9px;
                                          }
                                          .counter{
                                                margin-left: 0.5rem;
                                          }
                                    }
                              }
                              .or{
                                    text-align: center;
                                    margin-top: 1rem;
                                    a{
                                          font-size: 16px;
                                    }
                              }
                        }

                        .back{
                              margin-bottom: 3rem;
                              button{
                                    border: none;
                                    font-weight: 500;
                                    background: inherit;
                                    color: #fff;
                                    padding: 0;
                                    i{
                                          margin-right: 0.5rem;
                                    }
                              }
                        }
                        .content{
                              margin-bottom: 2rem;
                              p{
                                    font-weight: 500;
                                    color: #fff;
                                    font-size: 16px;
                              }

                              &.center{
                                    text-align: center;

                                    h3{
                                          font-size: 18px;
                                          border-bottom:  1px solid rgba(255, 255, 255, 0.18);
                                          padding-bottom: 3rem;
                                          margin-bottom: 3rem;
                                          color: #fff;
                                          i{
                                                font-size: 12px;
                                                margin-right: 1rem;
                                          }
                                    }
                                    p{
                                          font-size: 16px;
                                          font-weight: 500;
                                          margin-bottom: 1rem;

                                          &.password{
                                                font-size: 30px;
                                                font-weight: 600;
                                          }
                                    }
                              }
                        }
                  }
            }
      }
}

@media screen and (min-width: 1600px){
      .auth-page {
            .login-page {
                  .wp-form{
                        width: 900px;
                        padding: 80px 40px;
                        .form-admin-login{
                              .ant-form-item{
                                    margin-bottom: 15px;
                                    label{
                                          font-size: 24px;
                                    }
                                    .ant-input{
                                          height: 72px;
                                          font-size: 24px;

                                    }
                                    .ant-input-suffix{
                                          .ant-input-password-icon{
                                                font-size: 13px;
                                          }
                                    }
                                    .ant-input-prefix{
                                          .anticon{
                                                font-size: 18px;
                                          }
                                    }

                                    button[type=submit]{
                                          height: 72px;
                                          font-size: 22px;
                                    }
                              }
                        }
                  }
            }
      }
}

@media screen and (min-width: 1920px){
      .auth-page {
            .login-page {
                  .wp-form{
                        width: 1200px;
                        padding: 120px 50px;
                        .form-admin-login{
                              .ant-form-item{
                                    margin-bottom: 20px;
                                    label{
                                          font-size: 30px;
                                    }
                                    .ant-input{
                                          height: 90px;
                                          font-size: 30px;
                                    }
                                    .ant-input-suffix{
                                          .ant-input-password-icon{
                                                font-size: 16px;
                                          }
                                    }

                                    .ant-input-prefix{
                                          .anticon{
                                                font-size: 22px;
                                          }
                                    }

                                    button[type=submit]{
                                          height: 90px;
                                          font-size: 24px;
                                    }
                              }
                        }
                  }
            }
      }
}