.main-layout{
      height: 100vh;
      background: none !important;
      .header-top{
            text-align: center;
            display: flex;
            align-items: center;
            padding: 1rem 0;
            p{
                  padding: 10px 0;
                  font-weight: 500;
                  font-size: 16px;
                  font-family: var(--main-font-family);
                  width: 100%;
                  margin-bottom: 0;
                  > span{
                        text-transform: uppercase;
                        font-weight: 600;
                        font-size: 30px;
                        color: var(--main-text-color);
                  }
            }
            .change-language{
                  button{
                        font-weight: 500;
                        background: inherit;
                        border: none;
                        box-shadow: none !important;
                        outline: none !important;
                        width: 120px;

                        &:hover{
                              color: var(--main-text-color);
                        }

                        &::after{
                              content: none;
                        }
                  }
            }
      }
      .header{
            display: none;
            .wp-inner{
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
            }
            .right{
                  button{
                        margin-right: 10px;
                        &:last-child{
                              margin-right: 0;
                        }
                        &.btn-add-mobile{
                              display: none;
                        }
                  }
            }
            button{
                  color: #fff;
                  background: var(--button-background-color);
                  border: none;
                  text-transform: uppercase;
                  font-size: 13px;
            }

      }
      .sub-layout{
            //padding: 0 50px;
            background: none;

            .content-page{
                  margin: 0 15px !important;
                  //padding: 0 50px;
            }

            .sidebar-right{
                  width: 400px !important;
                  max-width: 400px !important;
                  min-width: 400px !important;
                  flex: none !important;
                  .ant-layout-sider-children{
                        background: var(--main-background-widget);
                        text-align: center;
                        padding: 3rem 2rem;
                        min-height: 100vh;
                        height: auto;

                        .content-sidebar{
                              .widget-head{
                                    button{
                                          width: 100%;
                                          margin-bottom: 10px;
                                          color: #fff;
                                          height: 40px;
                                          background: var(--main-background-button);
                                          border: none;
                                          text-transform: uppercase;
                                          font-size: 13px;
                                          outline: none !important;
                                          box-shadow: none !important;
                                          border-radius: 5px;
                                          font-weight: 500;
                                    }

                                    .ant-row{
                                          .ant-col{
                                                width: 100%;
                                          }
                                    }
                              }

                              .widget-body{
                                    margin-top: 1.5rem;
                                    color: #fff;
                                    .title{
                                          padding-bottom: 1rem;
                                          font-size: 18px;
                                          text-transform: uppercase;
                                          color: #fff;
                                          font-weight: 500;
                                          border-bottom: 1px double #fff;
                                    }
                              }
                        }

                  }
            }
      }
}

.main-menu{
      &.ant-drawer-open{
            width: 350px !important;
      }

      .ant-drawer-content{
            background: var(--main-background-menu);
      }
      .head-drawer{
            display: flex;
            justify-content: space-between;
            align-items: center;
            vertical-align: center;
            margin-bottom: 2rem;
            .title{
                  margin: 0;
                  padding: 0;
                  font-size: 20px;
                  text-transform: uppercase;
                  color: #fff;
                  font-weight: 500;
                  border-bottom: 1px solid #fff;
            }
            button{
                  height: 0;
                  line-height: 0;
                  padding: 0;
                  margin: 0;
                  border: none;
                  color: #fff;
                  font-weight: 500;
                  font-size: 16px
            }
      }

      .body-drawer{
            .menu-sidebar{
                  background: inherit;
                  border: none;

                  .ant-menu-item{
                        color: #fff;
                        text-transform: uppercase;
                        padding: 0;

                        &.ant-menu-item-selected{
                              background: inherit;
                              border-bottom: 2px solid #fff;
                        }
                  }
            }

      }
}

.list-staff-working{
      color: #fff;
      .head, .body > .item{
            display: flex;
            justify-content: space-between;

            > div{
                  width: 50%;
            }
      }
      .head{
            p{
                  font-size: 16px;
                  text-transform: uppercase;
            }
      }
      .body{
            max-height: calc(100vh - 400px);
            overflow: auto;
            padding: 0 10px;
            .item{
                  padding: 5px 0;
                  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                  .staff{
                        text-align: left;

                        .name{
                              margin-bottom: 3px;
                              cursor: pointer;
                              &:hover{
                                    text-decoration: underline;
                              }
                        }
                        .check-in{
                              margin-bottom: 0;
                              .time{
                                    > span{
                                          position: relative;
                                          font-size: 10px;
                                          font-weight: 500;
                                          padding-left: 10px;
                                          display: inline-block;

                                          i{
                                                position: absolute;
                                                top: 50%;
                                                left: 0;
                                                transform: translateY(-50%);
                                                font-size: 5px;
                                          }
                                    }

                                    &.available{
                                          color: #4cd137;
                                    }
                                    &.break{
                                          color: #f1c40f
                                    }
                                    &.working{
                                          color: #e84118;
                                    }
                              }
                        }
                  }
                  .customer{
                        text-align: right;
                        .name{
                              margin-bottom: 3px;
                        }
                        .time{
                              font-size: 12px;
                        }
                  }
            }
      }
}

.modal-confirm-switch{
      font-family: var(--main-font-family);
      border-radius: 10px;

      .ant-modal-confirm-btns{
            width: 100%;
            display: flex;
            button{
                  width: 50%;
                  font-weight: 500;

                  &:hover{
                        border: 1px solid var(--main-background-color);
                        color: #000;
                  }

                  &:last-child{
                        background: var(--main-background-color);
                        border: none;

                        &:hover{
                              color: #fff;
                        }
                  }
            }
      }
}

@media screen and(max-width: 1023px){
      .main-layout{
            .header{
                  display: block;

            }
            .sub-layout{
                  .sidebar-right{
                        display: none;

                  }
            }
      }
}

@media screen and(max-width: 480px){
      .main-layout{
            .header{
                  display: block;
                  padding: 0 5px;

                  .right{
                        button{
                              &:last-child{
                                    margin-right: 0;
                              }
                              &.btn-add-large{
                                    display: none;
                              }
                              &.btn-add-mobile{
                                    display: inline-block;
                              }
                        }
                  }
            }
      }
}

@media screen and (min-width: 1600px) {
      .main-layout{
            .header{
                  button{
                        color: #fff;
                        background: var(--button-background-color);
                        border: none;
                        text-transform: uppercase;
                        font-size: 16px;
                  }

            }
            .sub-layout{
                  .sidebar-right{
                        width: 550px !important;
                        max-width: 550px !important;
                        min-width: 550px !important;
                        .ant-layout-sider-children{
                              .content-sidebar{
                                    .widget-head{
                                          button{
                                                font-size: 18px;
                                                height: 50px;
                                          }
                                    }

                                    .widget-body{
                                          margin-top: 3rem;
                                          color: #fff;
                                          .title{
                                                font-size: 28px;
                                          }
                                    }
                              }

                        }
                  }
            }
      }

      .main-menu{
            &.ant-drawer-open{
                  width: 500px !important;
            }

            .head-drawer{
                  .title{
                        font-size: 28px;
                  }
                  button{
                        font-size: 20px
                  }
            }
            .body-drawer{
                  .menu-sidebar{
                        .ant-menu-item{
                              margin-bottom: 20px;
                              .ant-menu-title-content{
                                    font-size: 20px;
                              }
                        }
                  }

            }
      }

      .list-staff-working{
            .head{
                  p{
                        font-size: 20px;
                  }
            }
            .body{
                  .item{
                        .staff{
                              .name{
                                    font-size: 18px;
                              }
                              .check-in{

                                    .time{
                                          padding-left: 15px;
                                          i{
                                                font-size: 8px;
                                          }
                                          > span{
                                                font-size: 18px;
                                          }
                                    }
                              }
                        }
                  }
            }
      }
}

@media screen and (min-width: 1920px) {
      .main-layout{
            .header{
                  button{
                        font-size: 18px;
                  }
            }
            .sub-layout{
                  .sidebar-right{
                        width: 550px !important;
                        max-width: 550px !important;
                        min-width: 550px !important;
                        .ant-layout-sider-children{
                              .content-sidebar{
                                    .widget-head{
                                          button{
                                                font-size: 22px;
                                                height: 80px;
                                                margin-bottom: 20px;
                                          }
                                    }

                                    .widget-body{
                                          .title{
                                                font-size: 34px;
                                          }
                                    }
                              }

                        }
                  }
            }
      }

      .main-menu{
            &.ant-drawer-open{
                  width: 650px !important;
            }

            .head-drawer{
                  .title{
                        font-size: 34px;
                  }
                  button{
                        font-size: 28px
                  }
            }
            .body-drawer{
                  .menu-sidebar{
                        .ant-menu-item{
                              margin-bottom: 40px;
                              .ant-menu-title-content{
                                    font-size: 26px;
                              }
                        }
                  }

            }
      }

      .list-staff-working{
            .head{
                  p{
                        font-size: 26px;
                  }
            }
            .body{
                  .item{
                        .staff{
                              .name{
                                    font-size: 24px;
                              }
                              .check-in{

                                    .time{
                                          padding-left: 20px;
                                          i{
                                                font-size: 11px;
                                          }
                                          > span{
                                                font-size: 22px;
                                          }
                                    }
                              }
                        }
                  }
            }
      }
}