.check-in-page {
      background: var(--primary-color);

      .title-form {
            padding: 2rem 0;
            color: #fff;
            font-family: var(--second-font-family);
            font-weight: 600;
            font-size: 30px;
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 2rem;
            display: flex;
            align-items: center;

            button {
                  background: inherit;
                  color: #fff;
                  border: none !important;
                  outline: none !important;
                  box-shadow: none !important;
                  padding: 0 3rem;
                  font-size: 16px;
            }
      }

      button[type=submit], button.submit {
            background: var(--second-button-background);
            border: none;
            font-weight: 500;
            height: 65px;
            border-radius: 30px;
            width: 100%;
            text-transform: uppercase;
            margin-top: 1rem;
            font-family: var(--main-font-family);
            color: #fff;
            font-size: 16px;
      }

      .ant-form-item-control-input-content {
            padding: 5px 10px;
            border-radius: 1rem;
            color: var(--primary-color);
            background: var(--primary-color);
            font-family: var(--main-font-family);
            font-weight: 500;
            text-align: center;
            border: 1px solid var(--color-border-input)
      }

      .ant-input {
            padding: 5px 10px;
            font-size: 30px;
            font-weight: 500;
            text-align: center;
            border: none;
            background: var(--primary-color);
            color: #fff;
            box-shadow: none !important;
            outline: none !important;

            &::placeholder {
                  font-size: 14px;
                  color: #d9d9d9;
                  font-weight: 300;
            }
      }

      .wp-inner{
            padding: 3rem;
      }
      .wp-flex {
            display: flex;
            flex-wrap: wrap;

            .left {
                  width: calc(45% - 3rem);

                  position: relative;
                  border-radius: 1rem;
                  padding: 1rem 3rem;
                  background: #fff;
                  margin-right: 3rem;

                  .thumb {
                        position: relative;
                        height: 0;
                        padding-bottom: 100%;
                        margin: 0 auto;
                        top: 50%;
                        transform: translateY(-50%);

                        img {
                              max-width: 100%;
                              overflow: hidden;
                        }
                  }
            }

            .right {
                  width: calc(55% - 3rem);
                  position: relative;

                  > .wp-form {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 100%;
                  }

                  label {
                        color: #fff;
                        font-size: 16px;
                        font-weight: 400;
                        font-family: var(--main-font-family);
                  }
            }
      }

      .left {
            padding: 0;

            .title-form {
                  color: #000;
            }

            .thumb {
                  transform: none;
            }

            .or-login {
                  display: block;

                  a {
                        background: var(--primary-color);
                        padding: 20px 20px;
                        border-radius: 1rem;

                  }
            }
      }

      .wp-softkey {
            .widget {
                  border-radius: 20px;
            }

            .widget-head {
                  text-align: center;

                  h3.title {
                        text-transform: uppercase;
                        color: var(--primary-color);
                        font-size: 20px;
                        font-weight: 600;
                        font-family: var(--main-font-family);
                        padding: 2rem 0;
                  }
            }

            .widget-body {
                  .softkeys {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        padding: 10px;
                        gap: 20px;

                        > li {
                              text-align: center;
                              cursor: pointer;

                              > span {
                                    font-family: var(--main-font-family);
                                    font-weight: 500;
                                    border-radius: 100%;
                                    display: inline-block;
                                    background-color: #F5F5F5;
                                    padding: 30px 38px;
                                    font-size: 20px;
                                    color: #ACACAC;
                                    border: 3px solid #fff;
                                    transition: all 0.25s;

                                    &.key-submit {
                                          font-size: 18px;
                                          padding: 30px 32px;
                                    }

                                    > i {
                                          font-size: 18px;
                                    }

                                    &:hover {
                                          background-color: var(--primary-color);
                                          color: #fff;
                                          border-color: var(--primary-color);
                                    }
                              }
                        }
                  }
            }
      }
}

.modal-confirm-login, .modal-confirm-delete{
      .anticon{
            color: var(--primary-color) !important;
      }
      .ant-modal-confirm-btns{
            button{
                  font-weight: 500;
                  border: none;
                  background: #e1e1e1;
                  &.ant-btn-primary{
                        background: var(--primary-color);
                  }
            }
      }
}

.modal-notify-new-member{
      .ant-modal-confirm-body{
            .anticon{
                  display: none;
            }
      }
      .ant-modal-confirm-btns{
            button{
                  margin-left: 0 !important;
            }
            .ant-btn-default{
                  display: none;
            }
            .ant-btn-primary{
                  width: 100%;
                  margin: 0;
                  height: 40px;
                  font-weight: 500;
                  background: var(--primary-color);
                  border: none;
            }
      }
      .ant-modal-confirm-content{
            p{
                  font-size: 16px;
                  font-weight: 500;
            }
      }
}