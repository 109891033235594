.empty-data{
      svg{
            width: 32px;
      }
      .ant-empty-description{
            font-weight: 500;
      }
}

.ant-popover-placement-top{
      .ant-popover-buttons{
            button{
                  padding: 0 20px;
            }
      }
}

.status {
      &.pending{
            color: #cdb500 !important;
      }
      &.waiting{
            color: #cdb500 !important;
      }
      &.service{
            color: #2A4FEB !important;
      }
      &.complete{
            color: #65DA8E !important;
      }
      &.cancel{
            color: #e67e22 !important;
      }
      &.deleted{
            color: #F04423 !important;
      }
      &.accepted{
            color: #2A4FEB !important;
      }
}

.d-none{
      display: none !important;
}

.no-info{
      font-size: 10px !important;
      text-transform: uppercase;
      color: #9c9c9c !important;
      font-weight: 300 !important;
}

.status{
      position: relative;
      font-size: 13px;
      padding-right: 10px;
      i{
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            font-size: 6px;
      }
}