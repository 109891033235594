.modal-appointment-detail{
      font-family: var(--main-font-family);
      .body-modal{
            .appointment-detail{
                  padding: 0 3rem;

                  .detail{
                        margin-bottom: 2rem;
                        .widget{
                              border-bottom: 1px solid #808080;
                              padding: 1rem 0;
                              > p{
                                    display: flex;
                                    justify-content: space-between;

                                    > span{
                                          width: 50%;
                                          color: #808080;

                                          &:last-child{
                                                text-align: right;
                                                font-weight: 500;
                                                color: #000;
                                                position: relative;
                                          }

                                          &.status{
                                                padding-right: 10px;
                                                i{
                                                      position: absolute;
                                                      font-size: 5px;
                                                      right: 0;
                                                      top: 50%;
                                                      transform: translateY(-50%);
                                                }
                                          }

                                          span{
                                                color: #000;
                                                font-weight: 500;
                                          }

                                          .ant-rate{
                                                font-size: 16px;

                                                span{
                                                      color: inherit;
                                                }
                                          }

                                          &.price{
                                                font-size: 20px;
                                                font-weight: 600;
                                          }

                                          &.no-review{
                                                color: #b8b8b8;
                                          }
                                    }
                                    > div:first-child{
                                          width: 40%;
                                    }
                                    > div:last-child{
                                          width: 60%;
                                    }
                                    > div{
                                          font-size: 16px;
                                          margin-bottom: 10px;
                                          &.title{

                                          }
                                          &.value{
                                                font-weight: 500;
                                                .staff{
                                                      button{
                                                            border: none;
                                                            background: #ff0000;
                                                            margin-left: 10px;
                                                            font-weight: 500;
                                                            color:#fff;
                                                            border-radius: 5px;
                                                      }
                                                }
                                                .choose-staff{
                                                      .ant-select{
                                                            width: 100%;
                                                      }

                                                      .btn-change-staff{
                                                            display: flex;

                                                            button{
                                                                  color: #fff;
                                                                  font-weight: 500;
                                                                  border: none;
                                                                  &:first-child{
                                                                        margin-right: 5px;
                                                                  }
                                                                  &.confirm{
                                                                        background: #4cd137;
                                                                  }
                                                                  &.cancel{
                                                                        background: red;
                                                                  }
                                                            }
                                                      }
                                                }
                                          }
                                    }
                              }

                              .images{
                                    width: 50%;
                                    margin-left: auto;
                                    display: grid;
                                    gap: 10px;
                                    grid-template-columns: repeat(3, 1fr);
                                    li{
                                          img{
                                                max-width: 100%;
                                                height: auto;
                                          }

                                          .ant-image-mask-info{
                                                font-size: 11px;
                                          }
                                    }
                              }
                        }


                        .action{
                              text-align: center;
                              margin-top: 2rem;
                              button{
                                    height: 40px;
                                    color: #fff;
                                    background: #0652DD;
                                    border: none;
                                    i{
                                          font-size: 11px;
                                          margin-right: 10px;

                                    }
                              }
                        }
                  }
                  .action-appointment{
                        padding-top: 10px;
                        border-top: 1px solid #eee;
                        text-align: center;
                        .status-title{
                              text-transform: uppercase;
                              font-weight: 500;

                        }
                        .btn-status{
                              button{
                                    border: none;
                                    color: #fff;
                                    font-weight: 500;
                                    background: var(--button-background-color);
                                    margin-right: 5px;
                                    transition: background-color 0.2s linear;
                                    &.current{
                                          background: #f1c40f;
                                    }
                                    &.disabled{
                                          background: #696969 !important;
                                    }
                                    &:hover{
                                          background: #f1c40f;
                                    }
                              }
                        }
                        .note{
                              margin-top: 10px;
                              p{
                                    margin-bottom: 5px;
                                    .service-name{
                                          font-weight: 500;
                                    }
                              }
                        }
                        .app-complete{
                              margin-top: 2rem;
                              box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                              font-weight: 500;
                              padding: 20px 0;
                              font-size: 16px;
                        }

                  }

                  .btn-actions{
                        display: flex;
                        justify-content: center;

                        button{
                              height: 40px;
                              color: #fff;
                              font-weight: 500;
                              border-radius: 5px;
                              outline: none;
                              box-shadow: none !important;
                              border: none;

                              &:nth-child(2){
                                    margin-left: 1rem;
                              }
                              i{
                                    margin-right: 10px;
                                    font-size: 11px;
                              }
                              &.delete-btn{
                                    background: #f04423;
                              }
                              &.accepted{
                                    background: #4768F2;
                              }
                              &.rejected{
                                    background: #f04423;
                              }
                              &.cancel{
                                    background: #C9C9C9;
                              }
                              &.cs-check-in{
                                    background: #BC40C1;
                              }
                        }
                  }
            }
      }
      .footer-modal{
            margin-top: 2rem;
            .pop-confirm-custom{
                  width: 100%;
            }
            button{
                  width: 100%;
                  color: #fff;
                  font-size: 16px;
                  font-weight: 500;
                  height: 46px;
                  background-color: var(--button-background-color);
                  border: none;
                  text-transform: uppercase;
                  > i{
                        font-size: 11px;
                        margin-right: 10px;
                  }
                  &:disabled{
                        background: #808080;
                  }
            }
      }
}