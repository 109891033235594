body{
      --button-background-color: #92278f;
      --main-text-color: #92278f;
      --main-background-color: #92278f;
      --main-background-form :  rgba(146, 39, 143,0.8);
      --main-background-widget: #9f28a3;
      --main-background-menu: rgba(146, 39, 143, 1);
      --primary-color: #92278f;
      --second-color: #4b94ce;
      --three-color: #be43c3;
      --main-font-family : 'Montserrat';
      --second-font-family : 'Baloo2';
      --main-background-input-upload: #F5E2F6;
      --main-background-button: #bc40c1;
      --main-background-block-login: #80148e;
      --color-border-input: #BC69BF;
      --background-input-upload: #feddff;
      --primary-button-background : #7d88e1;
      --second-button-background : #edb883;
      --tertiary-button-background : #efa860;
}

.wp-loading-data{
      text-align: center;
      padding: 5rem;

      .anticon, .ant-spin-text{
            color: var(--primary-color);
      }
}

/* width */
::-webkit-scrollbar {
      width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
      background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
      background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
      background: #555;
}
