.customer-header{
      background: #bc40c1;

      .wp-inner{
            padding: 1rem 3rem;
            display: flex;
            align-items: center;
            .name{
                  p{
                        margin: 0 20px 0 0;
                        color: #fff;
                        font-weight: 400;
                        span{
                              font-size: 20px;
                              cursor: pointer;
                        }
                  }
            }
            .btn-profile{
                  button{
                        background: #D559DA;
                        border: none;
                        color: #fff;
                        font-weight: 400;
                        height: 30px;
                        border-radius: 5px;
                        margin-right: 10px;
                        padding: 0 20px;

                        &.owner-logout{
                              background: inherit;
                              color: #fff;
                              padding: 0;
                              margin: 0;
                              i{
                                    font-size: 13px;
                              }
                        }

                  }
            }
      }
}