.modal-create-appointment{
      .customer-info , .request-info{
            > div{
                  padding: 0 1rem;
            }
            p.title{
                  text-transform: uppercase;
                  font-size: 16px;
                  font-weight: 500;
                  color: var(--main-text-color);
            }
      }
      .body-modal{
            .ant-form-item {
                  .ant-input {
                        &::placeholder {
                              font-size: 14px;
                        }
                  }
            }
      }

      .ant-form-item{
            label{
                  text-transform: uppercase;
                  font-size: 14px;
            }
            .ant-input{
                  font-weight: 500;
                  height: 100px;
                  &::placeholder{
                        font-size: 14px;
                        font-weight: 400;
                  }
            }
            .ant-select-selector .ant-select-selection-item{
                  font-weight: 500;
            }
            &.choose-staff{
                  margin-bottom: 5px;
            }
            .ant-picker{
                  width: 100%;
                  height: 56px;
                  font-size: 18px;

                  input{
                        color: var(--main-text-color);
                        font-weight: 500;
                        font-size: 18px;
                        &::placeholder{
                              font-size: 14px;
                              font-weight: 400;
                        }
                  }
            }

            .ant-picker:focus, .ant-picker:hover, .ant-picker-focused {
                  border-color: var(--button-background-color);
                  border-right-width: 1px;
                  box-shadow: none;
                  outline: 0;
            }

            .list-services{
                  display: flex;
                  flex-wrap: wrap;
                  margin-left: -2rem;

                  .ant-checkbox-wrapper{
                        width: calc(100% / 2 - 2rem);
                        display: block;
                        margin-left: 2rem;
                        margin-bottom: -3rem;

                        .ant-checkbox{
                              display: none;
                        }
                        > span{
                              background: #fff;
                              padding: 11px 10px;
                              margin: 15px 0;
                              color: var(--main-text-color);
                              font-weight: 500;
                              border: 1px solid var(--button-background-color);
                              border-radius: 10px;
                              width: 100%;
                              display: block;
                              line-height: 32px;
                        }

                        &.ant-checkbox-wrapper-checked{
                              > span{
                                    background: var(--button-background-color);
                                    color: #fff;
                              }
                        }
                  }
            }
      }
}