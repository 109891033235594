.list-appointment.staff-panel{
      //margin-top: 3rem;
      .widget{
            position: relative;
            height: 100%;
            min-height: calc(100vh - 100px);
            overflow: auto;
            background-color: #fff;
            -webkit-box-shadow: 0 0 12px #d3d3d3;
            box-shadow: 0 0 12px #d3d3d3;
            border-radius: 12px;

            .widget-head{
                  text-align: center;
                  margin: 1rem 0;
                  h3.title{
                        text-transform: uppercase;
                        font-size: 18px;
                        font-weight: 600;
                  }
            }

            .widget-body{
                  max-height: calc(100vh - 200px);
                  overflow-y: auto;
                  padding: 0.5rem;

                  &::-webkit-scrollbar {
                        width: 5px;
                  }

                  &::-webkit-scrollbar-track {
                        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                  }

                  &::-webkit-scrollbar-thumb {
                        background-color: darkgrey;
                        outline: 1px solid slategrey;
                  }

                  .appointments{
                        .item-appointment{
                              display: flex;
                              flex-wrap: wrap;
                              justify-content: space-between;
                              border: 1px solid #e4e4e4;
                              margin-bottom: 1rem;
                              padding: 0.5rem;


                              .status{
                                    font-size: 13px;
                                    font-weight: 500;
                                    position: relative;
                                    padding-right: 10px;
                                    display: inline-block;
                                    i{
                                          transform: translateY(-50%);
                                          font-size: 5px;
                                    }
                                    &.waiting{
                                          color: #fbc531;
                                    }
                                    &.in-processing{
                                          color: #44bd32;
                                    }
                                    &.finished{
                                          color: #e67e22;
                                    }
                                    &.complete, &.completed{
                                          color: #e67e22;
                                    }
                                    &.cancel{
                                          color: #c0392b;
                                    }
                              }

                              .btn-action{
                                    max-width: 100%;
                                    width: 100%;
                                    display: flex;
                                    flex-wrap: wrap;
                                    justify-content: center;
                                    margin-top: 1rem;
                                    button{
                                          width: calc(100% / 2 - 10px);
                                          border: none;
                                          color: #fff;
                                          font-weight: 500;
                                          height: 38px;
                                          border-radius: 5px;

                                          &:nth-child(2){
                                                margin-left: 10px;
                                          }

                                          &.accepted{
                                                background: #4768F2;
                                          }
                                          &.rejected{
                                                background: #f04423;
                                          }
                                          &.cancel{
                                                background: #808080;
                                          }
                                          &.cs-check-in{
                                                background: #BC40C1;
                                          }
                                          &.deleted{
                                                background: #f04423;
                                          }
                                          &.start-service{
                                                background: #4768F2;
                                          }
                                          &.finished{
                                                background: #33d01f;
                                          }
                                    }
                              }

                              > div{
                                    width: calc(100%);

                                    &:nth-child(2){
                                          text-align: center;
                                    }

                                    &:last-child{
                                          text-align: right;
                                    }
                                    p{
                                          margin-bottom: 10px;
                                    }
                              }
                              .item{
                                    display: flex;
                                    justify-content: space-between;

                                    &:first-child{
                                          cursor: pointer;
                                          background: #f8f8f8;
                                          margin-bottom: 1rem;
                                          p{
                                                margin-bottom: 0;
                                                padding: 5px 0;
                                          }
                                    }

                                    p{
                                          width: 50%;
                                          text-align: left;
                                          font-weight: 600;

                                          &.appointment-key{
                                                font-weight: 700;
                                          }

                                          &.customer-name{
                                                color: #4768F2;
                                                cursor: pointer;
                                          }
                                          span.staff-name{
                                                color: #4768F2;
                                                cursor: pointer;
                                                margin-left: 5px;
                                          }

                                          &.price-total{
                                                font-size: 18px;
                                                font-weight: 700;
                                          }

                                          &:last-child{
                                                text-align: right;
                                          }
                                    }
                              }

                              .total-price{
                                    width: 100%;
                                    padding-top: 2rem;
                                    .form-input{
                                          display: flex;
                                    }
                                    input{
                                          height: 38px;
                                          border: 1px solid var(--primary-color);
                                          box-shadow: none;
                                          font-weight: 600;
                                          font-size: 16px;
                                          text-align: center;
                                    }
                                    .label{
                                          margin: 0;
                                          font-weight: 500;
                                          text-align: left;
                                    }
                                    .btn-actions{
                                          margin-top: 1rem;
                                          display: flex;
                                          justify-content: center;
                                    }
                                    button{
                                          font-weight: 500;
                                          color: #fff;
                                          background: var(--button-background-color);
                                          border: none;
                                    }
                              }

                              .images-booking{
                                    margin-top: 1rem;

                                    .form-input{
                                          display: block;
                                          text-align: left;

                                          .label{
                                                margin-bottom: 0.5rem;
                                          }
                                    }
                              }
                        }
                  }
            }
      }
}

@media screen and (max-width: 760px){
      .list-appointment.staff-panel{
            //margin-top: 3rem;
            .ant-row{
                  display: block;
            }
            .widget{
                  margin-bottom: 10px;
            }
      }
}

@media screen and (min-width: 1600px){
      .list-appointment.staff-panel{
            //margin-top: 3rem;
            .widget{
                  .widget-head{
                        h3.title{
                              font-size: 24px;
                        }
                  }
                  .widget-body{
                        .appointments{
                              .item-appointment{
                                    .customer{
                                          .name{
                                                font-size: 22px;
                                          }
                                          .service{
                                                font-size: 20px;
                                                color: #000;
                                                font-weight: 500;
                                          }
                                    }
                                    .time{
                                          p{
                                                font-size: 20px;
                                                color: #00108f;
                                                border-bottom: 4px solid #eee;
                                          }
                                    }
                                    .staff{
                                          p{
                                                .request-title{
                                                      font-size: 18px;
                                                }
                                                .name{
                                                      font-weight: 500;
                                                      font-size: 20px;

                                                      &.not{
                                                            font-size: 18px;
                                                      }
                                                }

                                          }
                                          .status{
                                                font-size: 18px;
                                          }
                                    }
                              }
                        }
                  }
            }
      }
}

@media screen and (min-width: 1920px){
      .list-appointment.staff-panel{
            //margin-top: 3rem;
            .widget{
                  .widget-head{
                        h3.title{
                              font-size: 30px;
                        }
                  }
                  .widget-body{
                        .appointments{
                              .item-appointment{
                                    .customer{
                                          .name{
                                                font-size: 28px;
                                          }
                                          .service{
                                                font-size: 24px;
                                                color: #000;
                                                font-weight: 500;
                                          }
                                    }
                                    .time{
                                          p{
                                                font-size: 24px;
                                                color: #00108f;
                                                border-bottom: 4px solid #eee;
                                          }
                                    }
                                    .staff{
                                          p{
                                                .request-title{
                                                      font-size: 22px;
                                                }
                                                .name{
                                                      font-weight: 500;
                                                      font-size: 24px;

                                                      &.not{
                                                            font-size: 22px;
                                                      }
                                                }

                                          }
                                          .status{
                                                font-size: 18px;
                                          }
                                    }
                              }
                        }
                  }
            }
      }
}