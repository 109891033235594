.appointment-page{
      background: var(--primary-color);
      .wp-inner{
            margin: 0 auto;
            .form-appointment{
                  font-family: var(--main-font-family);
                  padding: 2rem 3rem;

                  .service-request{
                        display: flex;
                        flex-wrap: wrap;

                        .request{
                              width: 45%;
                              padding: 0 3rem 0 1rem;
                        }
                        .service{
                              width: 55%;
                              padding: 0 5rem 0 3rem;
                        }
                        .widget{
                              .widget-head{
                                    margin-bottom: 3rem;
                                    h3.title{
                                          font-weight: 600;
                                          color: #fff;
                                          text-transform: uppercase;
                                          font-size: 24px;

                                    }
                              }

                              &.request{
                                    min-height: 100vh;
                                    .widget-head{
                                          p.time-check-in{
                                                font-size: 16px;
                                                font-weight: 400;
                                                color: #fff;
                                                span{
                                                      margin-left: 5px;
                                                }
                                          }
                                    }

                                    .widget-body{
                                          .block{
                                                .label{
                                                      text-transform: uppercase;
                                                      color: #fff;
                                                      font-weight: 600;
                                                }
                                          }
                                          .request-body{
                                                .staff-list{
                                                      .choose-staff{
                                                            display: flex;
                                                            align-items: center;
                                                            margin-top: 20px;
                                                            margin-bottom: 3rem;

                                                            p.or{
                                                                  margin-bottom: 0;
                                                                  padding: 0 1rem;
                                                                  color: #fff;
                                                            }

                                                            button.any-staff{
                                                                  background: inherit;
                                                                  border: 1px solid var(--color-border-input);
                                                                  color: #fff;
                                                                  text-transform: uppercase;
                                                                  font-size: 14px;
                                                                  font-weight: 500;
                                                                  height: 48px;
                                                                  padding: 0 20px;
                                                                  border-radius: 1rem;

                                                                  &.selected{
                                                                        color: var(--primary-color);
                                                                        background: #fff;
                                                                  }

                                                                  .fa-check{
                                                                        margin-right: 10px;
                                                                        font-size: 13px;
                                                                  }
                                                            }
                                                            .ant-form-item{
                                                                  width: 100%;
                                                                  margin-bottom: 0;
                                                                  .ant-select{
                                                                        width: 100%;

                                                                        .ant-select-selector{
                                                                              height: 60px;
                                                                              width: 100%;
                                                                              background: #B841BC;
                                                                              border: none;
                                                                              color: #fff;
                                                                              outline: none;
                                                                              box-shadow: none;
                                                                              font-size: 16px;
                                                                              font-weight: 400;
                                                                              border-radius: 10px;

                                                                              .ant-select-selection-item, .ant-select-selection-placeholder{
                                                                                    line-height: 60px;
                                                                                    color: #fff;

                                                                              }
                                                                              .ant-select-selection-placeholder{
                                                                                    font-size: 14px;
                                                                              }


                                                                        }
                                                                        .ant-select-arrow{
                                                                              color: #fff;
                                                                              .anticon{
                                                                                    font-size: 11px;
                                                                              }
                                                                        }
                                                                  }
                                                            }


                                                      }
                                                }

                                                .salon-business-hour{
                                                      margin-bottom: 2rem;
                                                      .business-hour{
                                                            background: #850E89;
                                                            padding: 2rem;
                                                            p{
                                                                  display: flex;
                                                                  justify-content: space-between;
                                                                  font-weight: 400;
                                                                  font-size: 16px;
                                                                  margin-bottom: 1rem;
                                                                  color: #fff;
                                                                  span{
                                                                        width: 50%;
                                                                        text-align: left;
                                                                        &.today{
                                                                              color: var(--tertiary-button-background);
                                                                        }
                                                                        &:last-child{
                                                                              text-align: right;
                                                                        }
                                                                  }
                                                            }
                                                      }
                                                }


                                                .datetime-rq{
                                                      input.date-now{
                                                            height: 52px;
                                                            border-radius: 30px;
                                                            border: 1px solid var(--primary-color);
                                                            color: var(--primary-color);
                                                            background: #fff;
                                                            text-align: center;
                                                            font-size: 14px;
                                                            font-weight: 600;
                                                            text-transform: uppercase;

                                                      }
                                                }
                                          }
                                          .list-staff{
                                                width: 100%;
                                                > div{
                                                      width: 100%;
                                                }
                                                label{
                                                      width: 100%;
                                                }
                                                .ant-radio-inner::after {
                                                      background-color: var(--button-background-color);
                                                }

                                                .ant-radio-checked .ant-radio-inner {
                                                      border-color: var(--button-background-color);
                                                }

                                                .ant-radio-input:focus + .ant-radio-inner {
                                                      box-shadow: none;
                                                }

                                                .ant-radio-wrapper{
                                                      > span:not(.ant-radio){
                                                            color: var(--main-text-color);
                                                            font-size: 20px;
                                                            display: block;
                                                            width: 100%;
                                                            > div{
                                                                  display: flex;
                                                                  justify-content: space-between;

                                                                  span.status{
                                                                        font-size: 13px;
                                                                        font-weight: 500;
                                                                        color: #ff0000;
                                                                  }
                                                            }
                                                      }
                                                      .message-err{
                                                            font-size: 14px;
                                                            margin-top: 10px;
                                                            font-weight: 500;
                                                            color: #ff0000;
                                                      }
                                                }
                                          }
                                    }

                                    .voucher{
                                          min-height: 100px;
                                          .label{
                                                color: #fff;
                                                font-weight: 600;
                                                text-transform: uppercase;
                                                margin-right: 1rem;
                                          }
                                          button{
                                                box-shadow: none;
                                          }

                                          .input-voucher{
                                                display: flex;
                                                justify-content: space-between;
                                                margin-top: 1rem;

                                                > div{
                                                      width: 55%;
                                                }
                                                button{
                                                      width: 40%;
                                                }
                                                input, button{
                                                      height: 45px;
                                                }

                                                input{
                                                      border-radius: 1rem;
                                                      border: 1px solid var(--color-border-input);
                                                      color: #fff;
                                                      background: inherit;
                                                      text-align: center;
                                                      font-size: 16px;
                                                      font-weight: 500;
                                                      padding: 10px 20px;
                                                      box-shadow: none;
                                                }
                                                button{
                                                      font-weight: 500;
                                                      box-shadow: none;
                                                      border: none;
                                                      background-color: #3742fa;
                                                      color: #fff;
                                                }
                                          }
                                    }
                              }

                              &.service{
                                    min-height: 100vh;
                                    .widget-body{
                                          .ant-form-item{
                                                .ant-form-item-label{
                                                      text-align: center;
                                                      label{
                                                            font-weight: 400;
                                                            font-size: 16px;
                                                            color: var(--primary-color);
                                                            font-family: var(--main-font-family);
                                                      }
                                                }
                                                input{
                                                      height: 52px;
                                                      border-radius: 1rem;
                                                      border: 1px solid var(--primary-color);
                                                      color: var(--primary-color);
                                                      background: #fff;
                                                      text-align: center;
                                                      font-size: 20px;
                                                      font-weight: 400;
                                                }
                                                textarea{
                                                      border-radius: 1rem;
                                                      border: 1px solid var(--color-border-input);
                                                      color: #fff;
                                                      background: inherit;
                                                      text-align: center;
                                                      font-size: 20px;
                                                      font-weight: 400;
                                                      padding: 10px 20px;
                                                      &::placeholder{
                                                            font-size: 14px;
                                                      }
                                                }

                                                .ant-input:focus, .ant-input:hover, .ant-input-focused {
                                                      //border-color: var(--button-background-color);
                                                      border-right-width: 1px;
                                                      box-shadow: none;
                                                      outline: 0;
                                                }
                                                .ant-checkbox-wrapper + .ant-checkbox-wrapper{
                                                      margin: 0;
                                                }

                                          }

                                          .list-services{
                                                display: flex;
                                                flex-wrap: wrap;

                                                .ant-checkbox-wrapper{
                                                      width: 50%;
                                                      padding: 0 2rem 2rem 0;

                                                      &:nth-child(2n){
                                                            padding-right: 0;
                                                      }

                                                      .ant-checkbox{
                                                            display: none;
                                                      }


                                                      > span{
                                                            background: inherit;
                                                            padding: 10px;
                                                            color: #fff;
                                                            font-weight: 600;
                                                            border: 1px solid var(--color-border-input);
                                                            border-radius: 1rem;
                                                            width: 100%;
                                                            //display: block;
                                                            line-height: 32px;
                                                            text-transform: uppercase;
                                                            //text-align: center;
                                                            position: relative;
                                                            display: flex;
                                                            //justify-content: space-between;
                                                            //align-items: center;

                                                            .ant-avatar{
                                                                  margin-right: 0.5rem;
                                                                  border-radius: 5px;
                                                            }

                                                            .fa-check{
                                                                  font-size: 10px;
                                                                  position: absolute;
                                                                  top: 50%;
                                                                  left: 15px;
                                                                  transform: translateY(-50%);
                                                                  color: var(--primary-color);
                                                                  display: none;
                                                            }
                                                      }

                                                      &.ant-checkbox-wrapper-checked{
                                                            > span{
                                                                  background: #fff;
                                                                  color: var(--primary-color);
                                                                  .ant-avatar{
                                                                        margin-left: 2rem;
                                                                  }
                                                                  .fa-check{
                                                                        display: block;
                                                                  }
                                                            }
                                                      }
                                                }
                                          }
                                    }
                                    .submit-appointment{
                                          display: none;
                                    }
                              }
                        }

                        .submit-appointment{
                              margin-top: 2rem;
                              button[type=submit]{
                                    background: var(--tertiary-button-background);
                                    color: #fff;
                                    border: none;
                                    font-weight: 500;
                                    text-transform: uppercase;
                                    height: 62px;
                                    width: 100%;
                                    border-radius: 30px;

                                    &:disabled{
                                          background: #a9a9a9;
                                    }
                              }
                        }
                  }

            }
      }
      @media screen and (max-width: 1280px) {
            .wp-inner{
                  .form-appointment{
                        .service-request{
                              .widget{
                                    &.request{
                                          .widget-body{
                                                .request-body{
                                                      padding: 0 2rem;
                                                }
                                          }
                                    }
                              }
                        }

                  }
            }
      }

      @media screen and (max-width: 1023px) {
            .wp-inner{
                  .form-appointment{
                        .service-request{
                              .widget{
                                    &.request{
                                          .widget-body{
                                                .request-body{
                                                      padding: 0 2rem;
                                                      .staff-list{
                                                            .choose-staff{
                                                                  display: flex;
                                                                  flex-wrap: wrap;

                                                                  p.or{
                                                                        padding: 1rem 0;
                                                                  }
                                                                  button.any-staff{
                                                                        margin-right: 0;
                                                                        width: 100%;
                                                                  }
                                                                  .ant-form-item{
                                                                        width: 100%;
                                                                  }


                                                            }
                                                      }
                                                }
                                          }
                                    }

                                    &.service{
                                          .widget-body{
                                                .list-services{
                                                      .ant-checkbox-wrapper{
                                                            width: 100%;
                                                            padding: 0 0 2rem 0;
                                                      }
                                                }
                                          }

                                    }
                              }
                        }

                  }
            }
      }

      @media screen and (max-width: 767px) {
            .wp-inner{
                  .form-appointment{
                        .service-request {
                              flex-direction: column-reverse;
                              .widget{
                                    .widget-head{
                                          min-height: 5rem;
                                    }
                                    &.request, &.service{
                                          width: 100%;

                                    }
                                    &.request{
                                          padding: 0;
                                          height: 100%;
                                          min-height: auto;
                                          .submit-appointment{
                                                display: none;
                                          }
                                    }
                                    &.service{
                                          padding: 3rem 3rem 2rem 3rem;
                                          .submit-appointment{
                                                display: block;
                                          }
                                    }
                              }
                        }

                  }
            }
      }

      @media screen and (max-width: 767px) {
            .wp-inner{
                  .form-appointment{
                        .service-request {
                              flex-direction: column-reverse;
                              .widget{
                                    .widget-head{
                                          min-height: 5rem;
                                    }
                                    &.request, &.service{
                                          width: 100%;

                                    }
                                    &.request{
                                          padding: 0;
                                          height: 100%;
                                          min-height: auto;
                                          .submit-appointment{
                                                display: none;
                                          }
                                    }
                                    &.service{
                                          padding: 3rem 3rem 2rem 3rem;
                                          .submit-appointment{
                                                display: block;
                                          }
                                    }
                              }
                        }

                  }
            }
      }

      @media screen and (max-width: 480px) {
            .wp-inner{
                  .form-appointment{
                        .service-request {
                              flex-direction: column-reverse;
                              .widget{
                                    &.service{
                                          .widget-body{
                                                .list-services{
                                                      display: flex;
                                                      flex-wrap: wrap;
                                                      margin-left: -2rem;

                                                      .ant-checkbox-wrapper{
                                                            width: 100%;
                                                            margin-left: 0;
                                                            padding: 0 0 2rem 0;
                                                      }
                                                }
                                          }

                                    }
                              }
                        }

                  }
            }
      }

      @media screen and (min-width: 1600px){
            .wp-inner{
                  .form-appointment{
                        .service-request{
                              .widget{
                                    .widget-head{
                                          h3.title{
                                                font-size: 36px;
                                          }
                                    }
                                    &.request{
                                          .widget-head{
                                                p.time-check-in{
                                                      font-size: 24px;
                                                }
                                          }

                                          .widget-body{
                                                .block{
                                                      .label{
                                                            font-size: 20px;
                                                      }
                                                }
                                                .request-body{
                                                      .staff-list{
                                                            .choose-staff{
                                                                  button.any-staff{
                                                                        font-size: 20px;
                                                                        height: 72px;
                                                                        .fa-check{
                                                                              font-size: 14px;
                                                                        }
                                                                  }
                                                                  .ant-form-item{
                                                                        width: 100%;
                                                                        .ant-select{
                                                                              width: 100%;

                                                                              .ant-select-selector{
                                                                                    height: 72px;
                                                                                    font-size: 26px;
                                                                                    .ant-select-selection-item, .ant-select-selection-placeholder{
                                                                                          line-height: 72px;
                                                                                    }
                                                                                    .ant-select-selection-placeholder{
                                                                                          font-size: 16px;
                                                                                    }

                                                                              }
                                                                              .ant-select-arrow{
                                                                                    .anticon{
                                                                                          font-size: 14px;
                                                                                    }
                                                                              }
                                                                        }
                                                                  }


                                                            }
                                                      }

                                                      .salon-business-hour{
                                                            .business-hour{
                                                                  p{
                                                                        font-size: 20px;
                                                                  }
                                                            }
                                                      }

                                                      .datetime-rq{
                                                            .date-rq{
                                                                  height: 72px;
                                                                  .ant-picker-input{
                                                                        input{
                                                                              font-size: 20px;
                                                                              &::placeholder{
                                                                                    font-size: 16px;
                                                                              }
                                                                        }
                                                                  }
                                                            }
                                                            input.date-now{
                                                                  height: 72px;
                                                            }
                                                      }
                                                }
                                          }
                                    }


                                    &.service{
                                          .widget-body{
                                                .ant-form-item{
                                                      .ant-form-item-label{
                                                            text-align: center;
                                                            label{
                                                                  font-size: 24px;
                                                            }
                                                      }
                                                      textarea{
                                                            font-size: 26px;

                                                      }
                                                }

                                                .list-services{
                                                      .ant-checkbox-wrapper{
                                                            > span{
                                                                  padding: 20px;
                                                                  border-radius: 35px;
                                                                  font-size: 22px;

                                                                  .fa-check{
                                                                        font-size: 10px;
                                                                        position: absolute;
                                                                        top: 50%;
                                                                        left: 15px;
                                                                        transform: translateY(-50%);
                                                                        display: none;
                                                                  }
                                                            }

                                                            &.ant-checkbox-wrapper-checked{
                                                                  > span{
                                                                        .fa-check{
                                                                              display: block;
                                                                              font-size: 14px;
                                                                        }
                                                                  }
                                                            }
                                                      }
                                                }
                                          }
                                          .submit-appointment{
                                                display: none;
                                          }
                                    }
                              }

                              .submit-appointment{
                                    margin-top: 7rem;
                                    button[type=submit]{
                                          height: 72px;
                                          border-radius: 35px;
                                          font-size: 20px;
                                    }
                              }
                        }

                  }
            }
      }

      @media screen and (min-width: 1920px){
            .wp-inner{
                  .form-appointment{
                        .service-request{
                              .widget{
                                    .widget-head{
                                          h3.title{
                                                font-size: 44px;
                                          }
                                          p.time-check-in{
                                                font-size: 30px;
                                          }
                                    }
                                    &.request{
                                          .widget-body{
                                                .block{
                                                      .label{
                                                            font-size: 26px;
                                                      }
                                                }
                                                .request-body{
                                                      .staff-list{
                                                            .choose-staff{
                                                                  button.any-staff{
                                                                        font-size: 24px;
                                                                        height: 100px;
                                                                        .fa-check{
                                                                              font-size: 16px;
                                                                        }
                                                                  }
                                                                  .ant-form-item{
                                                                        width: 100%;
                                                                        .ant-select{
                                                                              width: 100%;
                                                                              .ant-select-selector{
                                                                                    height: 100px;
                                                                                    font-size: 28px;
                                                                                    .ant-select-selection-item, .ant-select-selection-placeholder{
                                                                                          line-height: 100px;
                                                                                    }

                                                                                    .ant-select-selection-placeholder{
                                                                                          font-size: 18px;
                                                                                    }

                                                                              }
                                                                              .ant-select-arrow{
                                                                                    .anticon{
                                                                                          font-size: 18px;
                                                                                    }
                                                                              }
                                                                        }
                                                                  }
                                                            }
                                                      }

                                                      .salon-business-hour{
                                                            .business-hour{
                                                                  p{
                                                                        font-size: 24px;
                                                                  }
                                                            }
                                                      }

                                                      .datetime-rq{
                                                            .date-rq{
                                                                  height: 100px;
                                                                  .ant-picker-input{
                                                                        input{
                                                                              font-size: 28px;
                                                                              &::placeholder{
                                                                                    font-size: 18px;
                                                                              }
                                                                        }

                                                                  }
                                                            }
                                                            input.date-now{
                                                                  height: 100px;
                                                                  font-size: 22px;
                                                            }
                                                      }
                                                }
                                          }
                                    }


                                    &.service{
                                          .widget-body{
                                                .ant-form-item{
                                                      .ant-form-item-label{
                                                            text-align: center;
                                                            label{
                                                                  font-size: 30px;
                                                            }
                                                      }
                                                      textarea{
                                                            font-size: 36px;
                                                      }
                                                }

                                                .list-services{
                                                      .ant-checkbox-wrapper{
                                                            margin-bottom: 10px;
                                                            > span{
                                                                  padding: 30px;
                                                                  border-radius: 35px;
                                                                  font-size: 30px;

                                                                  .fa-check{
                                                                        font-size: 16px;
                                                                  }
                                                            }

                                                            &.ant-checkbox-wrapper-checked{
                                                                  > span{
                                                                        .fa-check{
                                                                              font-size: 18px;
                                                                        }
                                                                  }
                                                            }
                                                      }
                                                }
                                          }
                                    }
                              }

                              .submit-appointment{
                                    margin-top: 7rem;
                                    button[type=submit]{
                                          height: 100px;
                                          border-radius: 50px;
                                          font-size: 30px;
                                    }
                              }
                        }

                  }
            }
      }

}

.staff-option{
      min-height: 42px !important;
      line-height: 42px !important;
      font-family: var(--main-font-family);

      .ant-select-item-option-content{
            font-weight: 500;
      }
}

.message-err{
      font-size: 14px;
      color: #ff0000;
      font-weight: var(--second-font-family);
}

.date-rq-dropdown{
      font-family: var(--main-font-family);
      .ant-picker-panel-container{
            .ant-picker-datetime-panel{
                  .ant-picker-header{
                        .ant-picker-header-view{
                              font-weight: 600;
                              button{
                                    font-weight: 600;
                              }
                        }

                  }

                  .ant-picker-date-panel{
                        .ant-picker-content{
                              tbody {
                                    td{
                                          font-weight: 400;
                                          &.ant-picker-cell-disabled{
                                                div{
                                                      text-decoration: line-through;
                                                }
                                          }
                                    }
                              }
                        }
                  }
                  .ant-picker-time-panel{
                        .ant-picker-content{
                              ul {
                                    li{
                                          font-weight: 400;
                                    }
                              }
                        }
                  }
            }
      }
      .ant-picker-footer{
            .ant-picker-ok{
                  width: 100%;
                  button{
                        width: 100%;
                        font-size: 12px;
                        font-weight: 600;
                        height: 40px;
                        border: none;
                        background: var(--primary-color);
                  }
            }
      }
}

@media screen and (min-width: 1600px){
      .staff-option{
            min-height: 72px !important;
            line-height: 72px !important;

            .ant-select-item-option-content{
                  font-size: 22px;
            }
      }

      .message-err{
            font-size: 20px;
            color: #fff;
      }
}
@media screen and (min-width: 1920px){
      .staff-option{
            min-height: 84px !important;
            line-height: 84px !important;

            .ant-select-item-option-content{
                  font-size: 26px;
            }
      }

      .message-err{
            font-size: 28px;
            color: #fff;
      }
}
.modal-error, .modal-success{
      font-family: var(--main-font-family);
      .ant-modal-confirm-title{

      }
      .ant-modal-confirm-content{
            font-weight: 500;
      }
      .ant-modal-confirm-btns{
            button{
                  width: 100%;
                  background-color: var(--primary-color);
                  box-shadow: none;
                  color: #fff;
                  border: none;
                  font-weight: 500;
                  font-size: 14px;
            }

      }
}
