.create-appointment-success-modal{
      font-family: var(--main-font-family);
      .body-modal{
            padding: 3rem;
            text-align: center;
            .icon-success{
                  font-size: 45px;
                  color: var(--primary-color);
                  margin-bottom: 1rem;
            }
            button{
                  background: var(--primary-color);
                  color: #fff;
                  font-weight: 500;
                  height: 48px;
                  width: 100%;
                  margin-top: 3rem;
                  border: none;
                  box-shadow: none;
                  outline: none;
                  font-family: var(--main-font-family);

                  &.view-appointment{
                        background: var(--tertiary-button-background);
                  }
            }
            .title{
                  font-size: 20px;
                  font-weight: 500;
                  padding-bottom: 1rem;
                  color: var(--primary-color);
                  border-bottom: 1px solid rgba(26, 26, 26, 0.11);
                  margin-bottom: 2rem;
            }

            .title-summary{
                  font-weight: 600;
            }

            label{
                  font-weight: 500;
                  color: #808080;
            }

            input, textarea{
                  border: none;
                  background: rgba(26, 26, 26, 0.05);
                  color: #000;
                  font-weight: 500;
                  padding: 8px 10px;
            }

      }
}