.modal-change-password-customer{
      .body-modal{
            label{
                  font-weight: 500;
                  font-size: 16px;
            }
            .ant-input{
                  padding: 5px 10px;
                  color: #000;
                  //border-radius: 30px;
                  height: 50px;
                  font-size: 30px;
                  background: inherit !important;
                  border: 1px solid #fff;
                  font-family: var(--main-font-family);
                  font-weight: 500;
                  text-align: center;
            }

            .ant-input:focus, .ant-input:hover, .ant-input-focused {
                  border-color: #fff;
                  border-right-width: 1px;
                  box-shadow: none;
                  outline: 0;
            }
            .ant-input-affix-wrapper {
                  position: relative;
                  width: 100%;
                  min-width: 0;
                  padding: 0 11px;
                  font-size: 14px;
                  line-height: 1.5715;
                  border: none;
                  border-bottom: 1px solid #d9d9d9;
                  //border-radius: 30px;
                  transition: all 0.3s;
                  display: inline-flex;
                  background: inherit;
                  color: #fff;
                  box-shadow: none;

                  > .ant-input{
                        border: none;
                  }
                  .ant-input-password-icon{
                        color: #000;
                        cursor: pointer;
                        font-size: 11px;
                        margin-right: 1rem;
                  }
            }

            button{
                  background: var(--primary-color);
                  color: #fff;
                  font-weight: 500;
                  height: 48px;
                  width: 100%;
                  margin-top: 1rem;
                  border: none;
                  box-shadow: none;
                  outline: none;
                  font-family: var(--main-font-family);
                  border-radius: 5px;
            }
      }

}