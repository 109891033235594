.modal-detail{
      width: 520px !important;

      .detail{
            p{
                  display: flex;
                  justify-content: space-between;

                  span{
                        width: 50%;
                        color: #000;
                        font-weight: 500;
                  }
            }
      }
}