.modal-update-profile-customer{
      .head-modal{
            button.logout{
                  background: var(--primary-color);
                  color: #fff;
                  font-weight: 500;
                  border-radius: 5px;
            }
      }
      form{
            .ant-input{
                  border: none;
                  border-bottom: 1px solid #808080;
                  border-radius: 0 !important;
                  background-color: #fff;
                  font-size: 18px;
            }

            .form-item{
                  .ant-form-item-label{
                        p{
                              margin-bottom: 0;
                        }
                        label{
                              span{
                                    color: #808080;
                                    margin-left: 0.5rem;
                                    font-size: 14px;
                              }
                        }
                        .sub-label{
                              color: #808080;
                              font-size: 14px;
                        }
                  }

                  .date-birth-date{
                        width: 100%;
                        height: 52px;
                        color: #000;
                        text-align: left;
                        background: var(--second-background-input-form);
                        border-radius: 0;
                        box-shadow: none;
                        outline: 0;
                        border: none;
                        border-bottom: 1px solid #808080;

                        input{
                              font-size: 18px;
                              color: var(--primary-color);
                        }
                  }
            }
      }
}
.date-birth-date-dropdown{
      font-family: var(--main-font-family);
      .ant-picker-panel-container{
            .ant-picker-date-panel{
                  .ant-picker-header{
                        .ant-picker-header-view{
                              font-weight: 600;
                              button{
                                    font-weight: 600;
                              }
                        }
                  }
                  .ant-picker-content{
                        tbody {
                              td{
                                    font-weight: 400;
                                    &.ant-picker-cell-disabled{
                                          div{
                                                text-decoration: line-through;
                                          }
                                    }
                              }
                        }
                  }
            }
      }
      .ant-picker-footer{
            .ant-picker-ok{
                  width: 100%;
                  button{
                        width: 100%;
                        font-size: 12px;
                        font-weight: 600;
                        height: 40px;
                        border: none;
                        background: var(--primary-color);
                  }
            }
      }
}