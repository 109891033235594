.support-content{
      font-family: var(--main-font-family);

      .title-page{
            font-size: 30px;
            text-align: center;
            padding: 3rem 0;
      }

      .widget-head{
            padding: 1rem 0;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid rgba(34,90,89,0.2);
            .thumb{
                  width: 50px;
                  display: block;
                  overflow: hidden;
                  margin-right: 10px;
                  img{
                        max-width: 100%;
                        height: auto;
                  }

            }
            h3.title{
                  font-size: 24px;
                  margin-bottom: 0;
                  font-weight: 700;
            }
      }

      .widget-body{
            background: #f7f7f7;
            padding: 3rem;

            > div{
                  background: #fff;
                  max-width: 960px;
                  margin: 0 auto;
            }

            .content{
                  padding: 2rem 3rem;
                  font-family: var(--second-font-family);

                  h4{
                        font-size: 22px;

                  }
                  p{
                        font-size: 16px;
                        font-weight: 300;
                  }
            }
      }
}